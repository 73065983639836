var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "xml-preview"
  }, [_vm.isLoaded ? _c('page-details', {
    attrs: {
      "category": _vm.category,
      "disableSaveToPdf": !_vm.tables.length,
      "title": _vm.title
    }
  }) : _vm._e(), _c('div', {
    staticClass: "xml-preview__container"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _vm.isLoaded ? _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('specs', {
    attrs: {
      "items": _vm.specs
    }
  }), _vm._l(_vm.tables, function (table) {
    return _c('div', {
      key: table.id,
      class: ['border-table', table.type == 'simple' ? 'border-table--simple' : '']
    }, [_c('p', {
      staticClass: "border-table__header"
    }, [_vm._v(_vm._s(table.label))]), _c('div', {
      staticClass: "border-table__box"
    }, [_c('b-table', {
      attrs: {
        "fields": table.fields,
        "items": table.items
      }
    })], 1)]);
  })], 2)])]) : _vm._e()], 1), _vm.isLoaded ? _c('div', {
    staticClass: "xml-preview__options"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "xml-preview__options-inner"
  }, [_c('GenproxButton', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#app-router',
      expression: "'#app-router'"
    }],
    attrs: {
      "icon": _vm.ChevronTopIcon,
      "outline": true
    }
  }, [_vm._v("Go to top")]), _c('GenproxButton', {
    staticClass: "filled gn-primary",
    attrs: {
      "isDisabled": !_vm.tables.length
    },
    on: {
      "click": function click($event) {
        return _vm.printPage();
      }
    }
  }, [_vm._v("Save to pdf")])], 1)])])])]) : _vm._e(), _vm.isLoaded ? _c('div', {
    staticClass: "xml-preview__footer"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "xml-preview__footer-inner"
  }, [_vm._m(0), _c('router-link', {
    staticClass: "xml-preview__footer-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/images/logo.png")
    }
  })])], 1)])])])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "xml-preview__footer-info"
  }, [_vm._v("Report generated by "), _c('a', {
    attrs: {
      "href": "https://fundequate.com"
    }
  }, [_vm._v("Fundequate.com ")])]);
}]

export { render, staticRenderFns }